@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-alice-carousel/lib/alice-carousel.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.slider {
  max-width: 100%;
  margin: auto;
}

.slider-track {
  top: 8px;
  height: 4px;
  background: linear-gradient(90deg, #0065cb 0%, #003972 100%);
}

.slider-track.slider-track-0 {
  background: rgb(232, 232, 232);
}

.slider-track.slider-track-2 {
  background: rgb(232, 232, 232);
}

.slider .thumb {
  width: 25px;
  height: 25px;
  cursor: grab;
  background: #fff;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.305);
  margin-top: -2px;
}

.alice-carousel__prev-btn-item {
  color: #0065cb;
  font-size: 32px;
  padding: 0 15px;
  border-radius: 50%;
  transition: all 0.2s;
  border: 2px solid white;
}

.alice-carousel__next-btn-item {
  color: #0065cb;
  font-size: 32px;
  padding: 0 15px;
  border-radius: 50%;
  transition: all 0.2s;
  border: 2px solid white;
}

.alice-carousel__next-btn-item:hover, .alice-carousel__prev-btn-item:hover {
  color: #0065cb;
}
.alice-carousel__next-btn-item {
  color: #0065cb;
  border: 2px solid #0065cb;
}

.alice-carousel__prev-btn-item {
  color: #0065cb;
  border: 2px solid #0065cb;
}

/*  */
.contact-text p {
  font-size: 17px;
}
.contact-text > *:not(:first-child) {
  margin-top: 8px;
}
.contact-rhombus {
  transform: skewX(-15deg);
}
.contact-text svg {
  margin: 0;
}
.contact-text > p {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: .75rem;
}
.text-right {
  text-align: right;
}
.top-text {
  left: auto !important;
  right: 1.75rem !important;
}
body.ReactModal__Body--open {
  overflow: hidden;
}
._lock .ReactModal__Overlay {
  overflow: hidden;
}


@media (max-width: 1024px) {
  .contact-text p {
    font-size: 16px;
  }
}
@media (max-width: 784px) {
  .contact-text p {
    font-size: 15px;
  }
}
@media (max-width: 776px) {
  .contact-text p {
    font-size: 17px;
  }
}
@media (max-width: 450px) {
  .interlayer__btn {
    padding: 1.25rem 2rem;
  }
}
@media (max-width: 385px) {
  .interlayer__btn {
    padding: 1rem 1.5rem;
    font-size: 13px;
  }
}
@media (max-width: 372px) {
  .contact-text p {
    font-size: 15px;
  }
}
@media (max-width: 356px) {
  .contact-logo {
    width: 92px;
  }
}
@media (max-width: 340px) {
  .interlayer__btn {
    padding: 1rem 1.25rem;
  }
}