.about__contact {
  justify-content: center;
  margin-top: 20px;
}
.contact-text > p {
  font-size: 22px;
  justify-content: center;
}
.about__container > div {
  align-items: center;
}
.header__logo-socials.about__tel-btn {
  position: static;
  transform: none;
  justify-content: center;
}
.about__tel-btn .header__logo-tel {
  font-size: 21px;
}
.header__logo-socials.about__tel-btn .header__logo-tel-icon {
  font-size: 24px;
  padding: 18px;
}

@media (max-width: 768px) {
  .about__photo {
    display: block;
  }
  .about__photo-diploma {
    display: none;
  }
}

@media (max-width: 500px) {
  .about__tel-btn {
    margin-top: 15px;
  }
}

@media (max-width: 360px) {
  .header__logo-socials.about__tel-btn .header__logo-tel-txt {
    padding-left: 20px;
  }
  .about__tel-btn .header__logo-tel {
    font-size: 16px;
  }
}