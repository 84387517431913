.feedback__checkbox {
  flex-direction: row-reverse;
  gap: 1.25rem;
}
.feedback-title {
  text-align: right;
}
.feedback__text-input {
  background: #fff;
}
@media (max-width: 767.9px) {
  .feedback__img {
    display: none;
  }
}