:root {
	--red: #d92626;
}

.country-button .interlayer__btn span:nth-child(1) {
	background: linear-gradient(to left, rgba(43, 8, 8, 0), var(--red));
}

.country-button .interlayer__btn span:nth-child(2) {
	background: linear-gradient(to top, rgba(43, 8, 8, 0), var(--red));
}

.country-button .interlayer__btn span:nth-child(3) {
	background: linear-gradient(to right, rgba(43, 8, 8, 0), var(--red));
}

.country-button .interlayer__btn span:nth-child(4) {
	background: linear-gradient(to bottom, rgba(43, 8, 8, 0), var(--red));
}
@keyframes animateTop {
	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
	100% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}

@keyframes animateRight {
	0% {
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
	}
	100% {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}
}

@keyframes animateBottom {
	0% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
	100% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

@keyframes animateLeft {
	0% {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}
	100% {
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
	}
}

.animated-button1 {
	background: linear-gradient(-30deg, rgb(0 102 203) 50%, rgb(0 128 255) 50%);
	padding: 20px 40px;
	margin: 12px;
	display: inline-block;
	-webkit-transform: translate(0%, 0%);
	transform: translate(0%, 0%);
	overflow: hidden;
	color: #f7d4d4;
	font-size: 20px;
	letter-spacing: 2.5px;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	-webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
	box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button1::before {
	content: "";
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgb(0 128 255);
	opacity: 0;
	-webkit-transition: 0.2s opacity ease-in-out;
	transition: 0.2s opacity ease-in-out;
}

.animated-button1:hover::before {
	opacity: 0.2;
}

.animated-button1 span {
	position: absolute;
}

.animated-button1 span:nth-child(1) {
	top: 0px;
	left: 0px;
	width: 100%;
	height: 5px;
	background: -webkit-gradient(
		linear,
		right top,
		left top,
		from(rgba(43, 8, 8, 0)),
		to(var(--red))
	);
	background: linear-gradient(to left, rgba(43, 8, 8, 0), var(--red));
	-webkit-animation: 2s animateTop linear infinite;
	animation: 2s animateTop linear infinite;
}

.animated-button1 span:nth-child(2) {
	top: 0px;
	right: 0px;
	height: 100%;
	width: 5px;
	/* background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(var(--red)));
    background: linear-gradient(to top, rgba(43, 8, 8, 0), var(--red)); */
	background: -webkit-gradient(
		linear,
		left bottom,
		left top,
		from(rgba(43, 8, 8, 0)),
		to(var(--red))
	);
	background: linear-gradient(to top, rgba(43, 8, 8, 0), var(--red));
	-webkit-animation: 2s animateRight linear -1s infinite;
	animation: 2s animateRight linear -1s infinite;
}

.animated-button1 span:nth-child(3) {
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 5px;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(rgba(43, 8, 8, 0)),
		to(var(--red))
	);
	background: linear-gradient(to right, rgba(43, 8, 8, 0), var(--red));
	-webkit-animation: 2s animateBottom linear infinite;
	animation: 2s animateBottom linear infinite;
}

.animated-button1 span:nth-child(4) {
	top: 0px;
	left: 0px;
	height: 100%;
	width: 5px;
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(43, 8, 8, 0)),
		to(var(--red))
	);
	background: linear-gradient(to bottom, rgba(43, 8, 8, 0), var(--red));
	-webkit-animation: 2s animateLeft linear -1s infinite;
	animation: 2s animateLeft linear -1s infinite;
}
