.formArea {
    border: 1px solid black;
    font-weight: 300;
    width: 100%;
    font-size: 16px;
    background: rgb(232, 232, 232);
    color: black;
    padding: 10px;
    height: 100px;
    box-sizing: border-box;
    resize: none;
}

.formArea:focus {
    outline: none;
}

.inputBlock {
    position: relative;
    width: 100%;
}

.formBlock {
    position: relative;
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input {
    border-bottom: black 1px solid;
    font-size: 16px;
    font-weight: 300;
    color: black;
    padding: 10px;
    resize: none;
    width: 100%;
    background: rgb(232, 232, 232);
}

.input:focus {
    outline: none;
}

.inputWrapper {
    display: flex;
    width: 100%;
    justify-content: left;
}

.error {
    color: red;
}

.wrapper {
    background: rgb(232, 232, 232);
    padding-bottom: 50px;
}

.buttonWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}

.button {
    background: rgb(232, 232, 232);
    color: #0066cb;
    padding: 15px 30px;
    border-radius: 5px;
    transition: 0.5s ease;
    cursor: pointer;
    border: #0065cb 1px solid;
}

.button:hover {
    background: #0066cb;
    color: rgb(232, 232, 232);
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.346);
}