nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: white;
	position: sticky;
	top: 0;
}

nav ul {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
}

nav ul li {
	list-style: none;
}

nav .menu {
	display: none;
	position: absolute;
	top: 1.5rem;
	right: 2.5rem;
	flex-direction: column;
	justify-content: space-between;
	width: 2.25rem;
	height: 2rem;
	cursor: pointer;
}

nav .menu1 {
	display: none;
	position: absolute;
	top: 1.5rem;
	right: 2.5rem;
	flex-direction: column;
	justify-content: space-between;
	width: 2.25rem;
	height: 2rem;
	cursor: pointer;
}

nav .menu span {
	height: 0.35rem;
	width: 100%;
	background-color: #dc1c2d;
	border-radius: 0.5rem;
}

nav .menu1 span {
	height: 0.35rem;
	width: 100%;
	background-color: #dc1c2d;
	border-radius: 0.5rem;
}

@media (max-width: 1279px) {
	nav .menu {
		display: flex;
	}

	nav .menu1 {
		display: flex;
	}

	nav {
		display: none;
	}

	nav ul {
		display: none;
		justify-items: start;
		flex-direction: column;
		width: 100%;
		margin-bottom: 0.25rem;
	}

	nav ul.open {
		display: flex;
	}

	nav ul li {
		width: 100%;
		text-align: center;
	}

	nav ul li a {
		margin: 0.2rem 0.5rem;
	}
}

/*  */
.header__nav {
	align-items: center;

	flex-direction: row-reverse;
}
.nav__wrapper {
	flex-direction: row-reverse;
}
nav .menu1,
nav .menu {
	left: 2.5rem;
}
nav .menu1 span:before {
	content: "";
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 0.4rem;
	border-radius: 0.5rem;
	background-color: #9e1722;
	transform: translate(2px, 1px);
}
.header__logo-wrap {
	justify-content: flex-end;
}
.header-logo {
	width: 11rem;
}

#header header {
	box-shadow: -1px 10px 31px -17px rgba(0, 0, 0, 0.75);
}
.header__logo-socials > * {
	display: inline-block;
}
.header__logo-socials {
	display: flex;
	align-items: center;
}
.nav__item .header__logo-socials {
	position: static;
	display: none;
}

/* General button style */
.btn {
	background: #e74c3c;
	color: #fff;
	cursor: pointer;
	/* padding: 0 20px; */
	margin: 15px 30px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	outline: none;
	position: relative;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	gap: 20px;
}
.btn:hover {
	background: #bf3d2f;
}
.header__logo-tel-icon {
	background: #c44538;
	transition: background 0.3s ease 0s;
	font-size: 20px;
	padding: 20px;
}
.btn:hover .header__logo-tel-icon {
	background: #8c2e24;
}
.header__logo-tel-txt {
	padding-left: 20px;
}
._logo-socials {
	display: none;
}
.header__logo-wrap .header__logo-socials .header__logo-tel:hover {
	background: #e74c3c;
}
.header__logo-wrap .header__logo-socials .header__logo-tel:hover .header__logo-tel-icon {
	background: #b53e31;
}
.header__logo-wrap .header__logo-socials .header__logo-tel:active:after {
	transform: translateY(0px);
}
.header__logo-wrap .header__logo-socials .header__logo-tel:after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	transform: translateY(5px);
	background-color: #c31414;
	z-index: -1;
	border-radius: 10px;
	transition: all 0.2s linear 0s;
}
.header__logo-wrap .header__logo-socials .header__logo-tel {
	border-radius: 10px;
	transition: all 0.3s ease 0s;
}
.header__logo-wrap .header__logo-socials .header__logo-tel .header__logo-tel-icon {
	border-radius: 0 10px 10px 0;
}
@media (min-width: 767.9px) and (max-width: 1535px) {
	.header__logo-tel-icon {
		padding: 15px;
	}
	.header__logo-tel-txt {
		font-size: 14px;
	}
}

@media (max-width: 1395px) {
	.header-logo {
		width: 7rem;
	}
}

@media (max-width: 1279px) {
	.nav__item {
		margin-top: 10px;
	}
	nav ul.open,
	.header__nav {
		flex-direction: column;
	}
	.header__nav {
		align-items: flex-end;
	}
	nav .menu1,
	nav .menu {
		top: 2.7rem;
	}

	.header__logo-socials,
	._logo-socials {
		display: flex;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	.header__logo-socials.header__logo-socials-reset-abs {
		top: 17px;
		transform: translate(-50%, 0);
	}
	.nav__item .header__logo-socials {
		display: flex;
		justify-content: center;
		transform: none;
	}
	.header__logo-socials._hidden {
		display: none;
	}
	.header__logo-socials.header__logo-socials-up {
		display: none;
	}
}

@media (max-width: 767.9px) {
	.header-logo {
		width: 44px;
	}
	nav .menu1 {
		top: 0.9rem;
	}
	.logo-wrap {
		width: 100%;
	}
	.header__logo-tel-icon {
		padding: 12px;
	}
	.header__logo-tel {
		font-size: 16px;
	}
	.header__nav {
		padding: 0;
	}
	nav .menu1,
	nav .menu {
		left: 0;
	}
	.header__logo-socials.header__logo-socials-reset-abs {
		top: -10px;
		transform: translate(-50%, 0);
	}
}

@media (max-width: 690px) {
	nav .menu1,
	nav .menu {
		left: 0.4rem;
	}
}

@media (max-width: 500px) {
	/* .header__logo-socials-reset-abs > a.header__logo-tel {
		margin: 15px 30px;
	} */
	/* .header__logo-tel {
		margin: 0;
	} */
}
@media (max-width: 450px) {
	.header__logo-tel {
		font-size: 15px;
	}
	.header__logo-tel-icon {
		padding: 10px;
		font-size: 18px;
	}
	.header__logo-socials.header__logo-socials-reset-abs {
		top: -7px;
		transform: translate(-50%, 0);
	}
}
@media (max-width: 360px) {
	.header__logo-wa {
		width: 90%;
		height: 90%;
	}
	.header__logo-tel {
		gap: 10px;
	}
	.header__logo-tel-txt {
		padding-left: 10px;
	}
	.header__logo-tel {
		font-size: 12px;
	}
	.header__logo-socials.header__logo-socials-reset-abs {
		top: -3px;
		transform: translate(-50%, 0);
	}
	.header__logo-tel-icon {
		padding: 8px;
		font-size: 16px;
	}
	.header__logo-wa-link {
		margin: 0 0.2rem;
	}
}
