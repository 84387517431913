.regions {
    background-color: aqua;
    cursor: pointer;
    padding: 10px 20px;
}

.input:focus {
    outline: none;
}

.inputWrapper {
    display: flex;
    width: 100%;
    justify-content: left;
}

.formPrice {
    border: 1px solid rgba(0,0,0,.203);
    /* 1px solid  */
    border-radius: 10px;
    width: 100%;
    font-size: 20px;
    background: white;
    color: black;
    padding: 10px;
    resize: none;
}

.priceWrap {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    gap: 20px;
}

.formPriceWrapper {
    position: relative;

}

.formPriceTitle {
    position: absolute;
    z-index: 0;
    left: 30px;
    top: -11px;
    background: white;
    padding: 0px 5px;
}

.steps {
    font-size: 18px;
}

.progressWrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background: rgb(232, 232, 232);
    padding: 20px;
    box-shadow: inset 0px 0px 20px 2px rgba(0, 0, 0, 0.1)
}

.progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 10px;
    background-color: white;
    width: 100%;
}

.progress__inner {
    height: 100%;
    width: 80%;
    background: #0065cb;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(90deg, #0065cb 0%, #003972 100%);
}

.header {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
}

.firstButtonWrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
}

.buttonWrapper {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
}

.endButtonWrap {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: center;
}

.disabledButton {
    border: 1px solid rgba(0, 0, 0, 0.35);
    width: max-content;
    color: rgba(0, 0, 0, 0.35);
    padding: 15px 30px;
    border-radius: 5px;
}

.backButton {
    border: 1px solid black;
    width: max-content;
    color: black;
    padding: 15px 30px;
    border-radius: 5px;
    transition: 0.5s ease;
    cursor: pointer;
}

.button {
    border: 1px solid #0065cb;
    width: max-content;
    color: #0065cb;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: 0.5s ease;
    cursor: pointer;
}

@media (max-width: 768px) {
    .button {
        padding: 10px 20px;
        font-size: 14px;
    }

    .backButton {
        padding: 10px 20px;
        font-size: 14px;
    }
}

.button:hover {
    box-shadow: 0px 0px 30px 4px #0065cb54;
    transform: translateY(-5px);
}

.backButton:hover {
    box-shadow: 0px 0px 30px 4px #00000054;
    transform: translateY(-5px);
}

.list {
    list-style: none;
    padding: 0;
}

.element {
    background: black;
    color: gainsboro;
    padding: 15px 30px;
    border-radius: 5px;
    transition: 0.5s ease;
    cursor: pointer;
    margin: 15px 0px;
}

.element:hover {
    background: gainsboro;
    color: black;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.346);
}

.values {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    padding-left: 10px;
    padding-right: 7px;
}

.image {
    width: 70px;
    height: 70px;
    position: absolute;
    top: -30px;
    left: -10px;
}

.formInput {
    border: 1px solid #0065cb82;
    border-radius: 5px;
    width: 100%;
    font-size: 18px;
    background: white;
    color: black;
    padding: 10px 15px;
    resize: none;
}

.inputBlock {
    margin-top: 10px;
    position: relative;
    width: 100%;
}

.formBlock {
    position: relative;
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}