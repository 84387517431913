.footer__socials > p {
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	gap: 0.75rem;
}
.footer__socials svg {
	margin-right: 0;
}
.footer__container {
	flex-direction: row-reverse;
	padding-bottom: 35px;
}
.footer__social {
	min-width: 2.5rem;
}
.footer__social[alt="whatsapp"] {
	width: 2.5rem;
}
.header__logo-socials.footer__tel {
	position: static;
	transform: none;
	display: flex;
	justify-content: center;
}
@media (max-width: 767.9px) {
	.footer__container {
		flex-direction: column;
	}
}
