.hero__content-mob > * {
	text-align: right;
}
.translate-x-52 {
	--tw-translate-x: -13rem;
}
.nav__arrow {
	margin: 40px auto 10px auto;
	width: 38px;
	height: 100px;
	position: absolute;
	left: 51%;
	transform: translateX(-50%);
	bottom: 0px;
}
.nav__arrow span {
	display: block;
	width: 30px;
	height: 30px;
	border-bottom: 5px solid #fff;
	border-right: 5px solid #fff;
	transform: rotate(45deg);
	margin: -16px 0;
	animation: nav__arrow 2s infinite;
}
.nav__arrow span:nth-child(2) {
	animation-delay: -0.2s;
}
.nav__arrow span:nth-child(3) {
	animation-delay: -0.4s;
}
.hero__content-mob > h2.text-white {
	text-shadow: 1px 1px 0 #5050506e, 2px 2px 0 #5050506e, 3px 3px 0 #5050506e, 4px 4px 0 #5050506e,
		5px 5px 0 #5050506e, 6px 6px 0 #5050506e, 7px 7px 0 #5050506e;
}
.hero__content-mob > * {
	text-shadow: 1px 1px 0 #ffffffb3, 2px 2px 0 #ffffffb3, 3px 3px 0 #ffffffb3, 4px 4px 0 #ffffffb3,
		5px 5px 0 #ffffffb3;
}
@keyframes nav__arrow {
	0% {
		opacity: 0;
		transform: translate(0, -20px) rotate(45deg);
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translate(0, 20px) rotate(45deg);
	}
}
